export const MIN_STRING_LENGTH = 1;
export const MAX_TITLE = 100
export const MAX_SUBTITLE = 200

export const CONTENT_STATUS = {
  ACTIVE: 2,
  INACTIVE: 1
}

export const CONTENT_STATUS_LABELS = {
  [CONTENT_STATUS.ACTIVE]: 2,
  [CONTENT_STATUS.INACTIVE]: 1
}

export const ORDER_MIN = 0
export const NAME_PATTERN = /^[a-zA-Z0-9@!#$^%&*()+=\-[\]\\‘';,./{}|“"`:<>?]+$/